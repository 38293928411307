<template>
  <div
    class="earning-wrap widget-wrapper"
    :style="{
      backgroundColor: fullDonation
        ? 'rgba(244, 233, 246, 1)'
        : $vuetify.theme.dark
        ? '#222'
        : '#fff',
      boxShadow: fullDonation
        ? '0 0 10px 0 rgba(144, 45, 169, 0.8)'
        : $vuetify.theme.dark
        ? '0px 0px 12px 0px rgba(1, 1, 1, 0.25)'
        : '0 0 6px 0 rgba(34, 34, 34, 0.15)',
    }"
  >
    <div>
      <div class="d-flex">
        <div class="image-wrapper">
          <v-img
            class="ttu-coin-img"
            width="52"
            height="52"
            :src="require('@/assets/badge-add-donation.svg')"
          />
        </div>
        <div class="ml-5 pr-10 d-flex flex-column">
          <block-text
            v-if="donations.donationPercentage === 0"
            :text="$t('earning.noDonationText')"
            type="body-2"
            weight="bold"
            class="ml-2"
            color="primary"
          />
          <div v-else class="d-flex align-end">
            <block-text
              :text="$t('earning.descriptionPart1')"
              type="body-2"
              color="secondary"
              :style="{ lineHeight: '24px', display: 'block' }"
            />
            <block-text
              :text="`${donations.donationPercentage}%`"
              type="headline"
              weight="bold"
              class="ml-2"
              color="primary"
            />
          </div>
          <block-text
            v-if="donations.donationPercentage !== 0"
            :text="$t('earning.descriptionPart2')"
            type="body-2"
            color="secondary"
            :style="{ lineHeight: '24px', display: 'block' }"
          />
        </div>
      </div>
      <div class="actions-wrap d-flex justify-end mt-7">
        <v-btn
          @click="openEarningRulesDialog"
          outlined
          rounded
          :elevation="0"
          text
          :color="'jungleGreen'"
          :ripple="false"
          :plain="false"
          :style="{
            maxWidth: $vuetify.breakpoint.smAndDown
              ? fullDonation
                ? '100%'
                : '194px'
              : fullDonation
              ? '100%'
              : '153px',
            height: '40px',
          }"
          class="text-capitalize body-1 flex-grow-1 font-weight-bold"
          :class="fullDonation ? '' : 'mr-5'"
          >{{ $t("earning.cta1") }}</v-btn
        >
        <v-btn
          v-if="!fullDonation"
          @click.native="goToDonatePage"
          outlined
          rounded
          :elevation="0"
          text
          :color="'jungleGreen'"
          :ripple="false"
          :plain="false"
          :style="{
            maxWidth: $vuetify.breakpoint.smAndDown ? '194px' : '153px',
            height: '40px',
          }"
          class="text-capitalize body-1 flex-grow-1 font-weight-bold"
        >
          <v-img
            width="22"
            height="22"
            class="mr-2"
            :style="{
              maxWidth: '22px',
            }"
            :src="require('@/assets/add-donation.svg')"
          />
          {{ $t("earning.cta2") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      donations: "auth/donationsPercentage",
    }),
    fullDonation() {
      return this.donations.donationPercentage === 100;
    },
  },
  methods: {
    openEarningRulesDialog() {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        headerIcon: "PhCheckCircle",
        dialogPath: "components/Dialogs/EarningRulesDialog",
        title: this.$t("earning.title"),
        payload: {
          ctaText1: "About Wallet",
          ctaText2: "Ok, Got it",
          ctaText3: "Earning rules",
        },
      });
    },
    goToDonatePage() {
      window.open(`${process.env.VUE_APP_WEB_BASE_URL}donate`, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.earning-wrap {
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .earning-wrap {
    box-shadow: $box-shadow-dark;
    border-radius: $radius-md;
  }
}
</style>
